import * as React from "react";

type Props = {};

const TeamGermanyLandingText: React.FC<Props> = (props) => {
	return (
		<div className="text-start opacity-60 mt-24 pb-8 lg:mb-10 px-[25px] lg:px-[380px]">
			<div className="mb-2">
				<h1 className="text-[13px] font-bold">Participation:</h1>
				<p className="text-[13px] font-normal">
					The organizer of the competition is Germany SGP Germany GmbH,
					Uellendahler Str. 353, 42109 Wuppertal (hereinafter referred to as the
					organizer) Mail:{" "}
					<a
						href="mailto:germanysailgpteam@sailgpger.com"
						className="text-[13px] font-normal ring-color"
					>
						germanysailgpteam@sailgpger.com
					</a>
				</p>
			</div>
			<div className="mb-2">
				<p className="text-[13px] font-normal">
					Participation in the competition is limited to participants of legal
					age who have their permanent residence in the EU. Employees of the
					organizer and their relatives are excluded from participation in the
					competition. Participation by means of electronic registration
					systems, so-called collection service providers and so-called
					disposable e-mails or trash mails will not be considered.
				</p>
			</div>
			<div className="mb-2">
				<p className="text-[13px] font-normal">
					Procedure of the competition: Participation in the competition is only
					possible online via the platform provided by our service provider
					Encore Fans Inc. The competition will take place between 19.07.2024
					(18:00h) and 22.07.2024 (23:59h Berlin Time). The following
					information is required to participate:
				</p>
				<ul className="list-disc list-inside">
					<li className="text-[13px] font-normal">First name and surname</li>
					<li className="text-[13px] font-normal">
						Subscribe to our newsletter
					</li>
					<li className="text-[13px] font-normal">
						Follow our Account on Instagram and or TikTok, Like and comment the
						give away post.
					</li>
				</ul>
			</div>

			<div className="mb-2">
				<p className="text-[13px] font-normal">
					Whoever has completed all the steps required for participation by the
					end of the prize draw at the latest will be entered into the first
					prize draw. The prize will be drawn from all participants whose
					entries meet these conditions of participation.
				</p>
			</div>
			<div className="mb-2">
				<p className="text-[13px] font-normal">
					The processing of the required personal data serves marketing purposes
					and is carried out in accordance with the data protection regulations
					in accordance with C.
				</p>
			</div>

			<div className="mb-2">
				<h1 className="text-[13px] font-bold">
					Execution and processing of the competition:
				</h1>
				<p className="text-[13px] font-normal">
					The winners will be selected at random by the organizer. The
					individual participant's chance of winning therefore depends largely
					on how many participants take part in the competition in total.
				</p>
			</div>
			<div className="mb-2">
				<p className="text-[13px] font-normal">
					The following prizes will be raffled off among the winners:
				</p>
			</div>
			<div className="mb-2">
				<p className="text-[13px] font-normal">
					3 x official Germany SailGP Drysuits made by Zhik in size L.
				</p>
			</div>
			<div className="mb-2">
				<p className="text-[13px] font-normal">
					We will contact the winners by e-mail within 7 working days of the end
					of the competition and inform them of the prize. By participating,
					participants agree that we may notify them in the event of a win. If
					it turns out that a person who is not entitled to win on the basis of
					points 3 or 4 has been drawn, the prize will be drawn again. If the
					winner does not respond within 14 days of the notification being sent,
					the entitlement to the prize will lapse and no new winner will be
					selected.
				</p>
				<p className="mt-2 text-[13px] font-normal">
					The prize will be sent by post after notification of the prize to the
					address provided by the participant upon request. The prize transfer
					and dispatch processing is carried out by the organizer or a
					commissioned service provider and can take up to 6 weeks. The claim to
					the prize will also be forfeited if the prize cannot be sent within
					two months of the first notification of the prize for reasons
					attributable to the winner.
				</p>
				<p className="mt-2 text-[13px] font-normal">
					By participating, the participant accepts the conditions of
					participation and declares that he/she has read the data protection
					provisions below.
				</p>
			</div>
			<div className="mb-2">
				<h1 className="text-[13px] font-bold">Other:</h1>
				<p className="text-[13px] font-normal">
					The organizer and the participating agency assume no liability for
					data loss, in particular in the course of data transmission, and other
					technical defects
				</p>
				<p className="mt-3 text-[13px] font-normal">
					The organizer reserves the right to exclude participants from the
					competition due to false information, manipulation or the use of
					unauthorized aids.
				</p>
				<p className="mt-3 text-[13px] font-normal">
					The organizer reserves the right to change these conditions of
					participation at any time without separate notification. In the event
					of modification, suspension or termination of the competition by the
					organizer, the participant shall not be entitled to any compensation
					claims against the organizer.
				</p>
				<p className="mt-3 text-[13px] font-normal">
					The law of the Federal Republic of Germany shall apply. Legal recourse
					is excluded.
				</p>
			</div>
			<div className="mb-2">
				<h1 className="text-[13px] font-bold">
					Privacy policy for the SGP Germany competition:
				</h1>
				<p className="mt-3 text-[13px] font-normal">
					We provide the following information on the collection of personal
					data for the SGP Germany competition.
				</p>
				<p className="mt-3 text-[13px] font-normal">
					In the following, we provide information about the collection of
					personal data when participating in our competition. Personal data is
					all data that can be related to you personally, e.g. name, address,
					e-mail addresses, user behavior.
				</p>
				<p className="mt-3 text-[13px] font-normal">
					The controller pursuant to Art. 4 (7) of the EU General Data
					Protection Regulation (GDPR) is Germany SGP Germany GmbH, Uellendahler
					Str. 353, 42109 Wuppertal, Mail:{" "}
					<a
						href="mailto:germanysailgpteam@sailgpger.com"
						className="text-[13px] font-normal text-[#c91235]"
					>
						germanysailgpteam@sailgpger.com
					</a>
				</p>
			</div>

			<div className="mb-2">
				<h1 className="text-[13px] font-bold">Your rights:</h1>
				<p className="text-[13px] font-normal">
					You have the following rights vis-à-vis us with regard to the personal
					data concerning you:
				</p>
				<ul className="list-disc list-inside">
					<li className="text-[13px] font-normal">Right of access,</li>
					<li className="text-[13px] font-normal">
						Right to rectification or erasure,
					</li>
					<li className="text-[13px] font-normal">
						Right to restriction of processing,
					</li>
					<li className="text-[13px] font-normal">
						Right to object to processing,
					</li>
					<li className="text-[13px] font-normal">
						Right to data portability,
					</li>
				</ul>
				<p className="text-[13px] font-normal">
					To exercise these rights, you can contact us using the contact details
					of the controller or the data protection officer given above.
				</p>
				<p className="text-[13px] font-normal">
					You also have the right to complain to a data protection supervisory
					authority about the processing of your personal data by us.
				</p>
			</div>

			<div className="mb-2">
				<h1 className="text-[13px] font-bold">
					Objection or revocation against the processing of your data:
				</h1>
				<p className="text-[13px] font-normal">
					If you have given your consent to the processing of your data, you can
					withdraw this consent at any time. Such a revocation affects the
					permissibility of the processing of your personal data after you have
					expressed it to us.
				</p>
				<p className="text-[13px] font-normal">
					Insofar as we base the processing of your personal data on the
					balancing of interests, you can object to the processing. This is the
					case if, in particular, the processing is not necessary for the
					performance of a contract with you, which is described by us in the
					following description of the functions. When exercising such an
					objection, we ask you to explain the reasons why we should not process
					your personal data as we have done. In the event of your justified
					objection, we will examine the situation and will either discontinue
					or adapt the data processing or show you our compelling reasons worthy
					of protection on the basis of which we will continue the processing.
				</p>
				<p className="text-[13px] font-normal">
					Collection of personal data when participating in the competition.
				</p>
			</div>

			<div className="mb-2">
				<h1 className="text-[13px] font-bold">Categories of data concerned:</h1>
				<p className="text-[13px] font-normal">
					We only process data that is required to carry out the competition or
					that you provide as part of the competition. This applies in
					particular to:
				</p>
				<ul className="list-disc list-inside">
					<li className="text-[13px] font-normal">E-mail address</li>
					<li className="text-[13px] font-normal">Name, first name</li>
					<li className="text-[13px] font-normal">
						If applicable, contact data for prize transfer (e.g. name, address)
					</li>
				</ul>
				<p className="text-[13px] font-normal">
					Furthermore, we collect the personal data that you provide when
					answering the optional questions as part of your participation.
				</p>
				<p className="text-[13px] font-normal">
					There is no legal or contractual obligation to provide this data;
					however, failure to provide the information may mean that we are
					unable to consider you when awarding the prizes.
				</p>
			</div>

			<div className="mb-2">
				<h1 className="text-[13px] font-bold">Purpose and legal basis:</h1>
				<p className="text-[13px] font-normal">
					Data processing is carried out for the purpose of running the
					respective competition. With regard to the personal data that you
					provide when answering the optional questions as part of your
					participation, the processing is carried out for the purpose of
					optimizing our customer offer.
				</p>
				<p className="text-[13px] font-normal">
					The legal basis in each case is Art. 6 para. 1 lit. b Alt. 1 of the
					General Data Protection Regulation (GDPR).
				</p>
			</div>

			<div className="mb-2">
				<h1 className="text-[13px] font-bold">Deletion:</h1>
				<p className="text-[13px] font-normal">
					The aforementioned data will be stored for a period of 3 years from
					the end of the competition (closing date for entries) and then
					deleted, provided there are no statutory retention obligations.
				</p>
				<p className="text-[13px] font-normal">
					Personal data of the winners will be stored for a period of 6 years
					from the end of the competition due to statutory retention periods and
					then deleted. The legal basis for the storage is then Art. 6 I lit. c
					GDPR.
				</p>
			</div>

			<div className="mb-2">
				<h1 className="text-[13px] font-bold">
					Transmission of personal data:
				</h1>
				<p className="text-[13px] font-normal">
					Your data may be transferred to the agency commissioned to run the
					competition and to the company Encore Fans Inc, New York, which
					provides the technical platform for running the competition. We have
					concluded an order processing contract with Encore. Personal data is
					therefore also transferred to the USA. With regard to the transfer of
					personal data to the USA, there is an adequacy decision on the EU-US
					Data Privacy Framework of the EU Commission within the meaning of Art.
					45 GDPR (hereinafter:{" "}
					<a
						className="text-[13px] font-normal text-[#c91235]"
						href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
						target="_blank"
					>
						DPF - Adequacy decision for the EU-US Data Privacy Framework |
						European Commission
					</a>
					).The operator of the service is certified under the DPF, so that the
					usual level of protection of the GDPR applies to the transfer.
				</p>
			</div>

			<div className="mb-2">
				<h1 className="text-[13px] font-bold">Kontakt</h1>
				<p className="text-[13px] font-normal">
					Andrea Hoeppner und Eva Puschmann <br />
					Hoeppner Sport- und Markenkommunikation GmbH <br />
					<a
						className="text-[13px] font-normal text-[#c91235]"
						href="mailto:sailgp@hoeppner-kommunikation.de"
					>
						sailgp@hoeppner-kommunikation.de
					</a>
					<br />
					DE: +49 40 271 636 30
				</p>
			</div>
		</div>
	);
};

export default TeamGermanyLandingText;
