import React from "react";
import { connect } from "react-redux";
import logo from "@assets/logo.png";
import Loader from "@components/Loader";
import Logo from "@components/Logo";
import Backgrounnd from "@components/Backgrounnd";
import check from "@assets/close.png";
import "./index.css";
import { Link } from "react-router-dom";
import ProgramBox from "@components/ProgramBox";
import { isProgramActive, setLandingBackground } from "@helpers/common";
import store from "@store/configureStore";
import TeamGermanyLandingText from "@pages/fans/LandingText/TeamGermany";
import OrlandoMagicLandingText from "@pages/fans/LandingText/OrlandoMagic";

interface Props {
	form: Object;
	type: any;
	addFormStep: (step: number) => void;
}
interface State {
	error: boolean;
	message: String;
}

class Start extends React.Component<Props | any, State> {
	state: State = {
		error: false,
		message: "",
	};


	//handle form steps when click on next button
	stepHandle = (step: number) => {
		this.props.addFormStep(step);
	};

	render() {
		const { creator, program } = this.props;
		const cname = creator?.creator?.username
		const pname = creator?.program.name
		const isActive = isProgramActive(program);
		const programData = creator.program?.config?.data;
		const loader = creator.isLoading;
		const token = store.getState()?.user?.token;

		return (
			<div
				className={`absolute grow w-full transition-all duration-1000 ease-in-out h-full text-center flex flex-col ${setLandingBackground(programData)}`}
			>
				{loader ? (
					<div className="flex items-center justify-center bg-white h-screen overflow-auto">
						<Loader height={30} width={30} white={true} />
					</div>
				) : (creator?.creator?.id) ? (
						<>
					<div className="h-full">
						<div className="flex flex-col h-full py-10 2xl:py-16 2xl:pb-5">
							<div className="flex flex-col justify-around lg:justify-between grow">
								<Logo className="xl:h-[auto] h-[auto] rounded-full xl:w-[200px] w-[150px] object-contain mx-auto" />
								<div>
								<h2 className="body-inlay-primary font-semibold lg:text-[42px] text-[28px] lg:max-w-[770px] max-w-[399px] lg:leading-tight leading-10 mx-auto text-center">
									<ProgramBox box="LANDING_PAGE_HEADING_TITLE">
										{creator?.creator?.username}
									</ProgramBox>
								</h2>
								<p className="text-center body-inlay-primary  text-[20px] font-normal">
								<ProgramBox box="LANDING_PAGE_DESCRIPTION">
									</ProgramBox>
								</p>
								<p className="text-center body-inlay-primary text-[20px] font-normal">
								<ProgramBox box="LANDING_PAGE_SECOND_DESCRIPTION">
								</ProgramBox>
								</p>
								</div>
								{isActive &&
								<div className={`${programData?.landing_page?.show_toc ? "mt-10": "mt-6"} space-y-5 lg:flex md:justify-end lg:flex-col`}>
								<Link
                                to={token ? `/auth` :`/${cname}/${pname}/login`}
                                className="flex items-center justify-center btn-inlay-primary font-medium lg:text-[22px] text-[18px] bg-btn  rounded-full w-[119px] h-[42px] mx-auto"
                                >enter</Link>
								</div>}
							</div>
							<div className="flex items-end justify-center lg:grow-1 grow">
								<ProgramBox box="LANDING_PAGE_BELOW_BUTTON_TEXT" />
							</div>
							<div className={`${programData?.landing_page?.show_toc ? "mt-[175px]": ""}`}>
						<ProgramBox box="POWERED_BY"></ProgramBox>
						</div>
						</div>
					</div>
					{programData?.landing_page?.show_toc &&
						(cname === "magicgermany" ? 
							<OrlandoMagicLandingText /> : 
						<TeamGermanyLandingText/>)
					}
						</>
				) : (
					<div
						className={`absolute grow w-full transition-all duration-1000 ease-in-out h-full text-center flex flex-col`}
					>
						<Backgrounnd className="getStarted">
							<div className="lg:bg-[#fff]/80 bg-[#fff]/70 h-full">
								<div className="flex flex-col h-full py-10 2xl:py-16 2xl:pb-5">
									<div className="bg-white shadow-xl max-w-[430px] w-full px-10 py-10 rounded-[25px] mx-auto flex lg:justify-between justify-around flex-col">
										<div>
											<div className="border-2 bg-[#FF0000] p-2 border-[#FF0000] mx-auto h-[38px] w-[38px] rounded-full">
												<img
													src={check}
													alt="logo"
													className="object-contain w-full h-full"
												/>
											</div>
											<p className="font-normal text-gray-700 text-[16px] mt-[15px]">
												Fan loyalty program does not exist
											</p>
											<p className="font-normal text-gray-600 text-[14px] mt-[35px]">
												You can explore{" "}
												<a href={`${process.env.REACT_APP_BASE_URL}/programs`}>
													more
												</a>
											</p>
											<div className="flex flex-col items-center justify-center gap-y-1 mt-[25px]">
												<p className="font-normal primary text-[11px] tracking-[2px]">
													Powered by
												</p>
												<a
													target={"_blank"}
													rel="noreferrer noopener"
													href="https://encore.fans/"
												>
													<img
														src={logo}
														alt="logo"
														className="h-[25px] w-[140px] object-contain"
														data-testid="logo"
													/>
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="flex items-end justify-center lg:grow-2 grow"></div>
							</div>
						</Backgrounnd>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ user, loader, creator }, ownProps) => {
	return {
		step: user.step,
		form: user.form,
		error: user.error,
		loader: loader.creatorLoader,
		authLoader: user.authLoader,
		data: loader.creatorData,
		assets: user.assets,
		creator: creator,
		program: creator.program,
		type: ownProps.type,
		actionItem: creator.actionItemDetail,
		navigate: ownProps.navigate,
		json: ownProps.json,
	};
};

const mergeProps = (stateProps: any, dispatchProps: any, type: any) => {
	const { dispatch } = dispatchProps;
	const { actions } = require("@redux/UserRedux");
	const { actions: loaderAction } = require("@redux/LoaderRedux");
	return {
		...stateProps,
		addFormStep: (step: number) => {
			dispatch(actions.addFormStep(step));
		},
		resetForm: (form: Object) => {
			dispatch(actions.resetForm(form));
		},
		authError: (error: Object) => {
			dispatch(actions.authError(error));
		},
		fetchCreatorAssets: (creator_id: any) => {
			actions.fetchCreatorAssets(dispatch, creator_id);
		},
		setloader: (data: any) => dispatch(loaderAction.setLoader(data)),
	};
};

export default connect(mapStateToProps, undefined, mergeProps)(Start);
