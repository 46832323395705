import * as React from "react";

type Props = {};

const OrlandoMagicLandingText: React.FC<Props> = (props) => {
	return (
		<div className="text-start mt-24 pb-8 lg:mb-10 px-[25px] lg:px-[380px] btn-inlay-primary">
			<div className="text-[13px] font-bold text-center">
				Ultimate Trip to Orlando Sweepstakes
			</div>
			<div className="text-[13px] font-bold text-center mt-3">
				Official Rules
			</div>
			<div className="text-[13px] font-bold text-start mt-3">
				No Purchase Necessary To Participate Or Win.
			</div>
			<div className="text-[13px] font-bold text-start mt-3">
				Void Where Prohibited.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"1) "}Description</span>: The
				Ultimate Trip to Orlando Sweepstakes (
				<span className="font-bold text-[13px]">"Promotion"</span>) is a random
				draw promotion. Odds of winning depend upon the total number of eligible
				entries received during the Promotion Period. The Promotion is
				administered by Encore Fans Inc. (
				<a className="underline ring-color" href="mailto:hello@encore.fans">
					hello@encore.fans
				</a>
				) (the
				<span className="font-bold text-[13px]"> "Promoter"</span>).
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"2) "}Eligibility</span>: The
				Promotion is open to permanent residents of Germany who are eighteen
				(18) years of age or older as at date of contest entry submission (each,
				an <span className="font-bold text-[13px]"> "Entrant"</span>).
			</div>

			<div className="text-[13px] font-normal mt-2">
				Employees, officers, directors, agents and representatives of the
				Promoter, Orlando Magic, Ltd. (
				<span className="font-bold text-[13px]"> "Sponsor"</span>), the National
				Basketball Association (
				<span className="font-bold text-[13px]"> "NBA"</span>) and its member
				teams, NBA Properties, Inc., and NBA Media Ventures, LLC (collectively,
				the <span className="font-bold text-[13px]"> "NBA Entities"</span>), and
				each of their respective parent companies, subsidiaries, affiliates,
				officers, governors, owners, distributors, retailers, agents,
				advertising/promotion agencies (hereafter collectively,{" "}
				<span className="font-bold text-[13px]"> "Released Parties"</span>), and
				each such individual's immediate family members (mother, father, sister,
				brother, child, husband, wife and their respective spouses, regardless
				of where they reside) and those living in their same households, whether
				or not related, are not eligible to participate in the Promotion or
				receive a prize.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"3) "} Promotion Period</span>:
				The Promotion starts at 04:00PM CET on 18 December 2024 and ends at
				11:59PM CET on 21 January 2025 (
				<span className="font-bold text-[13px]"> "Promotion Period"</span>).
				Registration and entries must be submitted during the Promotion Period.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"4) "} To Participate</span>:
				During the Promotion Period, visit{" "}
				<a
					className="underline text-[13px] ring-color"
					href={"http://orlandomagic.com/UltimateTrip"}
					rel="noreferrer"
					target={"_blank"}
				>
					http://orlandomagic.com/UltimateTrip
				</a>{" "}
				(<span className="font-bold text-[13px]">"Website"</span>) and click on
				the banners, buttons and/or links to access the Promotion registration
				page (the{" "}
				<span className="font-bold text-[13px]"> "Promotion Registration"</span>
				). To register, complete and submit the Promotion Registration form
				according to the instructions provided.{" "}
				<span className="bg-yellow-200 text-[13px] font-normal">
					Complete any two (2) actions
				</span>{" "}
				to be entered for your chance to win:
			</div>

			<ul className="list-disc list-inside mt-3">
				<li className="text-[13px] font-normal">
					Subscribe to the Orlando Magic eNews
				</li>
				<li className="text-[13px] font-normal">
					Follow Orlando Magic Germany (Handle de_orlandomagic) on Instagram
				</li>
				<li className="text-[13px] font-normal">
					Follow Orlando Magic Germany (Handle orlandomagic_de) on Tik Tok
				</li>
				<li className="text-[13px] font-normal">
					Download the Orlando Magic App
				</li>
			</ul>

			<div className="text-[13px] font-normal mt-2">
				In addition, you may be asked if you wish to receive promotional emails
				from the Orlando Magic. Consenting to receive promotional emails is
				optional and is not required for participation in this Promotion and
				will not impact your chances of winning.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">
					There is a limit of one (1) Promotion Registration form submission per
					person and per email address for the duration of the Promotion Period.
					Additional registration forms or entries received from any such person
					or email address after the entry is received from that person or email
					address will be void.
				</span>{" "}
				The use of script, macro or any other device to automate or subvert the
				registration process is prohibited and all such registrations will be
				void. Incomplete entries will not be accepted and are automatically
				disqualified. Entries made on behalf of another person will not be
				accepted and joint submissions are not allowed.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"5) "} Prize</span>: There will
				be one (1) Grand Prize winner (the{" "}
				<span className="font-bold text-[13px]">"Grand Prize Winner"</span>) of
				the Promotion.
			</div>

			<ul className="list-disc list-inside mt-3">
				<li className="text-[13px] font-normal">
					The Grand Prize consists of the following components, with an
					approximate aggregate retail value of{" "}
					<span className="bg-yellow-400 text-[13px] font-normal">
						$4,410 U.S. Dollars{" "}
						<span className="line-through bg-yellow-400 text-[13px] font-normal">
							{" "}
							€5,000.00
						</span>
					</span>
					(collectively, the “Grand Prize”; all details and specifications
					related to the Grand Prize shall be determined by Promoter and/or
					Sponsor in their sole discretion):
				</li>
			</ul>

			<ul className="list-disc list-inside mt-3 ml-9">
				<li className="text-[13px] font-normal">
					Two (2) Economy class round trip airline tickets from Berlin, Germany
					to Orlando, Florida. Specific flight and seat selection is the sole
					discretion of the Sponsor.
				</li>
				<li className="text-[13px] font-normal">
					Exact dates and times of flights will be determined by the Sponsor in
					their sole discretion.
				</li>
				<li className="text-[13px] font-normal">
					One (1) hotel room for two (2) in Orlando, Florida for a total of four
					(4) nights.Exact dates of hotel stay will be determined by the Sponsor
					in their sole discretion. Hotels and rooms to be chosen by the Sponsor
					in its sole discretion
				</li>
				<li className="text-[13px] font-normal">
					Two (2) tickets to the Orlando Magic vs. Dallas Mavericks game on
					Thursday March 27, 2025 at Kia Center, located at 400 W Church St,
					Orlando, Florida (the{" "}
					<span className="font-bold text-[13px]">"Venue"</span>). Specific
					tickets shall be determined by Sponsor in its sole discretion.
				</li>
				<li className="text-[13px] font-normal">
					Post-game Meet and Greet with Franz Wagner, Moritz Wagner, and Tristan
					da Silva.
				</li>
				<li className="text-[13px] font-normal">
					Grand Prize Winner can nominate one (1) friend or family member to
					attend with them (the{" "}
					<span className="font-bold text-[13px]">"Guest"</span>).
				</li>
			</ul>

			<div className="text-[13px] font-normal mt-2">
				Any and all other costs and expenses associated with the acceptance and
				use of the Grand Prize not specified herein as being awarded are the
				Winner's sole responsibility including, without limitation, applicable
				ground transportation, gratuities, merchandise, telephone calls,
				personal expenses of any nature for overnight layovers, meals and
				beverages, service charges, in-flight meals and entertainment; costs
				incurred to and from the departure point as the Grand Prize originate
				and terminate there, obtaining sufficient personal travel insurance
				prior to departure, if desired; obtaining and carrying all necessary
				travel documentation, such as passports and visas and complying with
				entry, health, customs and immigrations regulations and requirements.
			</div>

			<div className="text-[13px] font-normal mt-2">
				The Grand Prize Winner and the Guest must both be at least 18 years old
				unless the Guest is the child or other person for whom the Winner has
				parental or legal guardian responsibility.
			</div>

			<div className="text-[13px] font-normal mt-2">
				The Grand Prize Winner and the Guest are responsible for obtaining and
				carrying all necessary travel documentation, such as passports and visas
				and complying with entry, health, customs and immigrations regulations
				and requirements.
			</div>

			<div className="text-[13px] font-normal mt-2">
				In the event that the Grand Prize Winner or the Guest are unable to
				accept the Grand Prize or are unable to travel or participate in any
				part of the Grand Prize for any reason, the Grand Prize Winner in
				question will forfeit the Grand Prize in its entirety and, time
				permitting, the Grand Prize will be awarded to an alternate Grand Prize
				Winner that will be randomly selected from any other eligible Entrants.
				The Sponsor retains the right to postpone or substitute the Grand Prize
				with an item(s)/activity(s) of comparable or greater value as determined
				solely by the Sponsor and no additional compensation prize, cash or
				other alternative will be awarded to the Winners in such event.
			</div>

			<div className="text-[13px] font-normal mt-2">
				If the Grand Prize is canceled or postponed for any reason, the Sponsor
				will reschedule or substitute that Grand Prize(s) with an
				item(s)/activity(s) of comparable or greater value as determined solely
				by the Sponsor and no additional compensation prize, cash or other
				alternative will be awarded to the Grand Prize Winner in such event.
			</div>

			<div className="text-[13px] font-normal mt-2">
				The Grand Prize Winner and the Guest must comply with all Venue rules
				and regulations. Failure to do so may result in the forfeiture of the
				Grand Prize element. Exact tickets and seat locations at the Venue for
				the Orlando Magic games are to be determined by the Sponsor in its sole
				discretion. The Grand Prize is not negotiable or transferable.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">
					{"6) "} Winner Determination
				</span>
				: At or about 5:00 PM CET on 23 January 2025 (the{" "}
				<span className="font-bold text-[13px]">"Draw Date"</span>), one (1)
				potential Grand Prize winner will be selected by random draw from among
				all eligible entries received in accordance with these Rules (as
				determined by the Sponsor at its sole discretion) (the{" "}
				<span className="font-bold text-[13px]">"Winner"</span>). Odds of
				winning depend upon the total number of eligible entries received by the
				Sponsor during the Promotion Period.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">
					{"7) "} Winner Notification
				</span>
				: The Winner will be notified on or about 27 January 2025 by e-mail to
				the e-mail address provided during the registration process. Entrants
				are solely responsible for checking their e-mail inboxes to see if they
				are the Winners.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">
					To claim a Prize, each Winner must follow and complete the
					instructions in the win notification email within seventy-two (72)
					hours of the email notification being sent to such Winner.
				</span>{" "}
				If a Winner does not claim a Prize within this timeframe, such Winner's
				claim will be automatically withdrawn and the Sponsor will re-award the
				Prize to an alternate Winner selected by random draw in accordance with
				these Official Rules.
			</div>

			<div className="text-[13px] font-normal mt-2">
				If a Prize remains unclaimed by 5 February 2025 at 12:00pm CET, the
				Sponsor reserves the right to cancel the awarding of the unclaimed
				Prize.
			</div>

			<div className="text-[13px] font-normal mt-2">
				The Sponsor does not accept any responsibility if a Winner is unable to
				claim the Prize.
			</div>

			<div className="text-[13px] font-normal mt-2">
				The potential Winners and the Guest (if applicable) will be required to
				execute and return an{" "}
				<span className="bg-yellow-200 text-[13px]">
					Affidavit of Eligibility
				</span>
				, Liability Release and any other documents required by Sponsor within
				one (1) day of date of receipt of documents. The Guest (or the Winner if
				the Guest is the Winner's child or legal ward) must also execute and
				return an Affidavit of Eligibility, Liability Release and any other
				documents required by Sponsor within one (1) day of date of receipt of
				documents.{" "}
			</div>

			<div className="text-[13px] font-normal mt-2">
				If the e-mail notifying a Winner is returned as undeliverable, or if a
				potential Winner is not in compliance with these Official Rules, such
				potential Winner will be disqualified and, time permitting, Sponsor will
				re-award the applicable Prize to an alternate Winner selected in
				accordance with these Official Rules (if one exists).
			</div>

			<div className="text-[13px] font-normal mt-2">
				Prizes will be awarded to the person named on the applicable Promotion
				Registration form regardless of whether that is the authorized account
				holder of the email address from which said Promotion Registration form
				was submitted (subject to verification of eligibility and compliance
				with the terms of these Official Rules).
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"8) "} Miscellaneous</span>: By
				participating in the Promotion, each Entrant accepts and agrees to
				accept and be bound by these Official Rules and by the decisions of the
				Promoter and/or Sponsor, which shall be final and binding in all
				respects.
			</div>

			<div className="text-[13px] font-normal mt-2">
				No transfer, assignment, cash redemption, or substitution of the Prizes
				(or portion thereof) is permitted.
			</div>

			<div className="text-[13px] font-normal mt-2">
				The Released Parties and their parents, affiliated and subsidiary
				companies and advertising and promotion agencies are not responsible for
				lost, incomplete, inaccurate, garbled, late, delayed, undelivered or
				misdirected entries, registrations, usernames, NBA team submissions
				(including but not limited to the predictions, selections or revisions);
				theft, destruction or unauthorized access to, or alteration of, NBA
				team; failures or malfunctions of phones/mobile devices (including
				wireless phones/handsets or other wireless devices); interrupted or
				unavailable network, server, Internet Service Provider (ISP) or other
				connections; any error, omission, interruption, defect or delay in any
				transmission or communication; traffic congestion on the Internet or for
				any technical problem, including, but not limited to, any injury or
				damage to Entrant's or any other person's computer or mobile device
				related to or resulting from participation in this Promotion; printing
				errors in any Promotion-related advertisements or other materials; the
				cancellation, suspension or preemption of NBA games, scoring tabulation
				or timing errors.
			</div>

			<div className="text-[13px] font-normal mt-2">
				By entering, each Entrant agrees that the Released Parties shall have no
				liability and will be held harmless by Entrant for any claim, action,
				liability, loss, injury or damage (x) to personal or real property, due
				in whole or in part, directly or indirectly, by reason of the
				acceptance, possession, use or misuse of any Prize, any other
				Prize-related activity, any travel or travel-related activity or
				participation in this Promotion, and (y) any and all taxes and other
				fees applicable to the Prize received by a Winner or the Guest. Nothing
				in these Official Rules shall exclude the Released Parties' liability
				for death or personal injury caused by its negligence or for any other
				matter where liability may not as a matter of law be limited.
			</div>

			<div className="text-[13px] font-normal mt-2">
				The Released Parties, in their sole discretion, reserve the right to:{" "}
				{"1)"}
				disqualify any person (and void his/her points) who tampers with the
				submission process, the operation of the Promotion, or any other
				component of this Promotion, or who is otherwise in violation of these
				Official Rules; and {"2)"} modify to the minimum extent necessary,
				suspend or terminate this Promotion (or any portion thereof) should
				virus, bugs, non-authorized human intervention or other causes,
				including but not limited to war, strikes, and/or acts of God, corrupt
				or impair the administration, security, fairness or proper play of this
				Promotion. The Released Parties reserve the right to halt or modify the
				Promotion at any time during the Promotion Period if events beyond its
				control compromise the Promotion's fairness or integrity.
			</div>

			<div className="text-[13px] font-normal mt-2">
				The Promoter and/or Sponsor reserves the right to modify these rules for
				clarification purposes without materially affecting the Official Rules
				of the Promotion. This is a Promotion designed to increase consumer
				awareness of and interest in the Released Parties' service.
			</div>

			<div className="text-[13px] font-normal mt-2">
				In the event that the Promotion is challenged by any legal or regulatory
				authority, Released Parties reserve the right to discontinue or modify
				the Promotion. In such event, the Sponsor shall have no liability to any
				Entrants who are disqualified due to such an action. In the event of any
				ambiguity(s) or error(s) in these Official Rules, the Released Parties
				reserve the right to modify these Official Rules for clarification
				purposes or to correct any such ambiguity or error(s) without materially
				affecting the Official Rules of the Promotion.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">
					{"9) "} Limitation of Liability and Disclaimer of Warranties
				</span>
				:{" "}
				<span className="uppercase text-[13px] font-normal">
					IN NO EVENT WILL THE RELEASED PARTIES OR THEIR RESPECTIVE OFFICERS,
					DIRECTORS, EMPLOYEES, REPRESENTATIVES AND AGENTS, BE RESPONSIBLE OR
					LIABLE FOR ANY DAMAGES OR LOSSES OF ANY KIND, INCLUDING, BUT NOT
					LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL ARISING OUT
					OF YOUR ACCESS TO AND USE OF THE PRIZES AND/OR THE PROMOTION,
					DOWNLOADING FROM AND/OR PRINTING MATERIAL DOWNLOADED FROM THE
					PROMOTION. IN NO EVENT SHALL THE RELEASED PARTIES' TOTAL LIABILITY TO
					YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED $100. WITHOUT
					LIMITING THE FOREGOING, EVERYTHING IN THE PROMOTION INCLUDING THE
					PRIZES IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER
					EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
					WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
					NON-INFRINGEMENT. SOME JURISDICTIONS MAY NOT ALLOW THE LIMITATIONS OR
					EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR
					EXCLUSION OF IMPLIED WARRANTIES SO SOME OF THE ABOVE LIMITATIONS OR
					EXCLUSIONS MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR ANY
					RESTRICTIONS OR LIMITATIONS REGARDING THESE LIMITATIONS OR EXCLUSIONS.
				</span>
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"10) "} Privacy</span>:
				Information submitted by Entrant will be subject to Sponsor's and the
				Promoter's Privacy Policies. The Sponsor and Promoter collect personal
				data from Entrants when they enter the Promotion. Personal data
				collected from the website is subject to the Sponsor's privacy policy
				located at{" "}
				<a
					className="underline text-[13px] ring-color"
					href={"https://www.nba.com/privacy-policy"}
					rel="noreferrer"
					target={"_blank"}
				>
					https://www.nba.com/privacy-policy
				</a>{" "}
				and the Promoter privacy policy located at{" "}
				<a
					className="underline text-[13px] ring-color"
					href={"https://legal.encore.fans/Encore_Privacy_Policy.pdf"}
					rel="noreferrer"
					target={"_blank"}
				>
					https://legal.encore.fans/Encore_Privacy_Policy.pdf
				</a>{" "}
				and will be handled consistent with these policies and subject to any
				specific privacy choices communicated to the Sponsor. By entering the
				Sweepstakes, Entrant acknowledges that Entrant has read and agreed to
				the privacy policies, and that Entrant consents to the collection, use
				and disclosure of Entrant's personal information in accordance with the
				Policies. By entering the Sweepstakes, Entrant also agrees that if
				he/she is selected as a Winner, then he/she is bound by Sponsor's Terms
				& Conditions.
			</div>

			<div className="text-[13px] font-normal mt-2">
				Additional questions regarding this privacy policy can be sent to the
				following:
			</div>

			<ul className="list-disc list-inside mt-3">
				<li className="text-[13px] font-normal">
					By writing to us at Orlando Magic, Ltd, 400 W, Church St., Suite 250,
					Orlando, Florida, 32801, Attn: Legal Services.
				</li>
				<li className="text-[13px] font-normal">
					By sending an email to{" "}
					<a className="underline ring-color" href="mailto:hello@encore.fans">
						hello@encore.fans
					</a>
				</li>
			</ul>

			<div className="text-[13px] font-normal mt-2">
				Personal information may also be disclosed to third parties solely to
				administer the Promotion and award the Prize.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"11) "}</span> These Official
				Rules are governed by German law. The courts of Germany shall have
				exclusive jurisdiction to hear any dispute or claim arising in
				association with the Promotion or these Official Rules.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"12) "}</span> The Promotion
				and the terms and conditions contained in these Official Rules are
				subject to all National Basketball Association (
				<span className="font-bold text-[13px]">"NBA"</span>) rules,
				regulations, resolutions, and agreements, as they may be modified or
				amended from time to time.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"13) "} Sponsor</span>: Orlando
				Magic, Ltd., 400 W, Church St., Suite 250, Orlando, Florida, 32801.
				<span className="font-bold text-[13px]">Promotor</span>: Encore Fans
				Inc.{" "}
				<a className="underline ring-color" href="mailto:hello@encore.fans">
					hello@encore.fans
				</a>
				.
			</div>
		</div>
	);
};

export default OrlandoMagicLandingText;
